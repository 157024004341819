import { useState, useRef, useEffect } from "react";

import NProgress from "nprogress";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Link,
  useColorModeValue,
  useDisclosure,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Avatar,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import {
  SunIcon,
  MoonIcon,
  BellIcon,
  AddIcon,
  CalendarIcon,
} from "@chakra-ui/icons";

import logo from "../images/logo.png";

import { NavLink, Link as RouteLink, useLocation } from "react-router-dom";

import "./css/utils.css";

import axios from "axios";
import { routes } from "../config/api_routes";
import { useDispatch, useStore } from "react-redux";
import { cases } from "../_services/caseDeclarations";

import { css } from "@emotion/react";
import { useNotificationsCount } from "./app/notifications/useNotificationsCount";

export default function Navbar() {
  const [userInfo, setUserInfo] = useState({
    userID: "",
    avatar: "",
  });

  let store = useStore();

  const [isAuthenticated, setIsAuthenticated] = useState({
    is: store.getState().isAuthenticated,
    token: store.getState().token,
  });

  store.subscribe(() =>
    setIsAuthenticated({
      is: store.getState().isAuthenticated,
      token: store.getState().token,
    })
  );

  useEffect(() => {
    if (isAuthenticated.is) {
      axios
        .get(`${routes.API_URL}auth/get-user-info/`, {
          headers: {
            Authorization: `Token ${isAuthenticated.token}`,
          },
        })
        .then((response) => {
          let data = response.data;
          let user_id = data["user_id"];
          let avatar = data["avatar"];

          avatar = `${routes.CONTENT_URL}${avatar}`;

          setUserInfo({ ...userInfo, userID: user_id, avatar: avatar });
        })
        .catch((error) => console.error(error));
    }
  }, [isAuthenticated]);

  const logoutToast = useToast();

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
        >
          <Flex flex={{ base: 1 }} justify={{ base: "start", md: "start" }}>
            <Link as={RouteLink} to="/">
              <Image
                // textAlign={useBreakpointValue({ base: "center", md: "left" })}
                w="8"
                h="8"
                src={logo}
                alt="Orsive"
              />
            </Link>
          </Flex>

          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <ThemeButton />

            {isAuthenticated.is ? (
              <>
                <Flex alignItems={"center"}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={"full"}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                    >
                      <Avatar size={"sm"} src={userInfo.avatar} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        as={RouteLink}
                        to={`/profile/${userInfo.userID}`}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          NProgress.configure({
                            showSpinner: false,
                            easing: "ease",
                            speed: 500,
                          });
                          NProgress.start();
                          store.dispatch({ type: cases.logout });

                          logoutToast({
                            title: "Logged out successfully",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });

                          NProgress.done();
                        }}
                        // as={Button}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </>
            ) : (
              <>
                <LoginModal />
                <SignUpModal />
              </>
            )}
          </Stack>
        </Flex>
      </Box>
      <BottomNavigation />
    </>
  );
}

function NotificationIcon({ count }: { count: number }) {
  return (
    <>
      <BellIcon />
      {count !== 0 && (
        <Box
          borderRadius="50%"
          h="5"
          w="5"
          as={"span"}
          color={"white"}
          position={"absolute"}
          top={"6px"}
          right={"4px"}
          fontSize={"0.8rem"}
          bgColor={"red"}
          zIndex={9999}
          p={"1px"}
        >
          <Text textAlign="center">{count}</Text>
        </Box>
      )}
    </>
  );
}

function BottomNavigation() {
  let count = useNotificationsCount();

  return (
    <Box
      zIndex="2"
      position="fixed"
      bottom={0}
      w="full"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p="2"
      bg="black"
      mt="5"
    >
      <Button
        as={NavLink}
        to="/feed"
        justifyContent="center"
        flex={1 / 3}
        exact
        activeStyle={{ backgroundColor: "blue" }}
        w="full"
        py="2"
        size="lg"
      >
        <CalendarIcon />
      </Button>
      <Box display="flex" justifyContent="center" flex={1 / 3}>
        <Menu>
          <MenuButton
            isRound
            size="lg"
            colorScheme="blue"
            py="2"
            aria-label="post content"
            as={IconButton}
            icon={<AddIcon />}
          />
          <MenuList>
            <RouteLink to="/feed/post/question">
              <MenuItem>Question</MenuItem>
            </RouteLink>
            <RouteLink to="/feed/post/image">
              <MenuItem>Image</MenuItem>
            </RouteLink>
            <RouteLink to="/feed/post/article">
              <MenuItem>Article</MenuItem>
            </RouteLink>
          </MenuList>
        </Menu>
      </Box>

      <Button
        as={NavLink}
        to="/notifications"
        justifyContent="center"
        flex={1 / 3}
        activeStyle={{ backgroundColor: "blue" }}
        w="full"
        py="2"
        size="lg"
      >
        <IconButton
          bg="none"
          _hover={{}}
          css={css`
            position: relative !important;
          `}
          py={"2"}
          colorScheme={"gray"}
          size={"lg"}
          aria-label="notifications"
          icon={<NotificationIcon count={count} />}
        />
      </Button>
    </Box>
  );
}

function ThemeButton() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <IconButton
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.600", "white")}
      onClick={toggleColorMode}
      aria-label="Toggle Theme"
      icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
    />
  );
}

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

function SignUpModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allFormState, setFormState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  let signUpButton: any = useRef(null);
  const accountCreated = useToast();

  function handleFormSubmit(event: any) {
    event.preventDefault();
    let body = JSON.stringify(allFormState);
    NProgress.configure({ showSpinner: false, easing: "ease", speed: 500 });
    NProgress.start();
    axios
      .post(`${routes.API_URL}auth/user-register/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        accountCreated({
          title: "Account created.",
          description: "You can now login with your credentials",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        accountCreated({
          title: "Something wrong happened :(",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        NProgress.done();
      });
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const newState: any = JSON.parse(JSON.stringify(allFormState));
    newState[name] = value;

    if (name == "password" || name == "confirm_password") {
      if (newState.password != newState.confirm_password) {
        signUpButton.current.disabled = true;
      } else {
        signUpButton.current.disabled = false;
      }
    }
    setFormState(newState);
  }

  return (
    <>
      <Button
        // display={{ base: "none", md: "inline-flex" }}
        fontSize={"sm"}
        fontWeight={600}
        color={"white"}
        bg={"pink.400"}
        href={"#"}
        _hover={{
          bg: "pink.300",
        }}
        onClick={onOpen}
      >
        Sign Up
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sign Up</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleFormSubmit}>
              <FormControl id="first_name" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  onChange={handleInputChange}
                  value={allFormState.first_name}
                  name="first_name"
                  type="text"
                />
              </FormControl>
              <FormControl id="last_name" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  onChange={handleInputChange}
                  value={allFormState.last_name}
                  name="last_name"
                  type="text"
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  onChange={handleInputChange}
                  value={allFormState.email}
                  name="email"
                  type="email"
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input
                  onChange={handleInputChange}
                  value={allFormState.password}
                  name="password"
                  type="password"
                />
              </FormControl>
              <FormControl id="confirm_password" isRequired>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  onChange={handleInputChange}
                  value={allFormState.confirm_password}
                  name="confirm_password"
                  type="password"
                />
              </FormControl>
              <Button
                onClick={onClose}
                my="2"
                type="submit"
                ref={signUpButton}
                disabled
              >
                Sign Up
              </Button>
            </form>
            {allFormState.password != allFormState.confirm_password ? (
              <Text my="2" color="red">
                Passwords do not match
              </Text>
            ) : null}

            {allFormState.password.length < 5 ? (
              <Text my="2" color="red">
                Password should have atleast 5 characters
              </Text>
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function LoginModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const [allFormState, setFormState] = useState({
    email: "",
    password: "",
  });

  const loginToast = useToast();

  function handleFormSubmit(event: any) {
    event.preventDefault();
    let body = JSON.stringify({
      username: allFormState.email,
      password: allFormState.password,
    });
    NProgress.configure({ showSpinner: false, easing: "ease", speed: 500 });
    NProgress.start();
    axios
      .post(`${routes.API_URL}auth/token-auth/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let data = response.data;
        let token = data.token;
        dispatch({ type: cases.auth_user, data: token });
        loginToast({
          title: "Logged In successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        let errorObj = error.response.data;

        if (errorObj.non_field_errors) {
          loginToast({
            title: errorObj.non_field_errors[0],
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        NProgress.done();
      });
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const newState: any = JSON.parse(JSON.stringify(allFormState));
    newState[name] = value;
    setFormState(newState);
  }

  return (
    <>
      <Button
        // display={{ base: "none", md: "inline-flex" }}
        fontSize={"sm"}
        fontWeight={600}
        color={"white"}
        bg={"green.400"}
        href={"#"}
        _hover={{
          bg: "green.300",
        }}
        onClick={onOpen}
      >
        Login
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleFormSubmit}>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  value={allFormState.email}
                  onChange={handleInputChange}
                  name="email"
                  type="email"
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input
                  onChange={handleInputChange}
                  name="password"
                  type="password"
                  value={allFormState.password}
                />
              </FormControl>
              <Button type="submit" my="2">
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

// const NAV_ITEMS: Array<NavItem> = [
//   {
//     label: "Inspiration",
//     children: [
//       {
//         label: "Explore Design Work",
//         subLabel: "Trending Design to inspire you",
//         href: "#",
//       },
//       {
//         label: "New & Noteworthy",
//         subLabel: "Up-and-coming Designers",
//         href: "#",
//       },
//     ],
//   },
//   {
//     label: "Find Work",
//     children: [
//       {
//         label: "Job Board",
//         subLabel: "Find your dream design job",
//         href: "#",
//       },
//       {
//         label: "Freelance Projects",
//         subLabel: "An exclusive list for contract work",
//         href: "#",
//       },
//     ],
//   },
//   {
//     label: "Learn Design",
//     href: "#",
//   },
//   {
//     label: "Hire Designers",
//     href: "#",
//   },
// ];

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Feed",
    href: "/feed",
  },
];
