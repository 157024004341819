import ReduxState from "./stateType";
import { cases } from "./caseDeclarations";

export default function reducer(
  state: any,
  action: { type: string; data: any }
) {
  const newState: ReduxState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case cases.auth_user:
      localStorage.setItem("token", action.data);
      newState.token = action.data;
      newState.isAuthenticated = true;

      return newState;

    case cases.logout:
      newState.isAuthenticated = false;
      newState.token = "";
      localStorage.removeItem("token");

      return newState;

    case cases.changeIsAuth:
      newState.isAuthenticated = action.data;
      newState.isReady = true;
      return newState;

    default:
      return newState;
  }
}
