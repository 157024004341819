import { useQuery } from "react-query";
import { useState } from "react";
import useClient from "../useClient";
import { useLocation } from "react-router-dom";

export const useNotificationsCount = () => {
  const location = useLocation();

  const [count, setCount] = useState(0);
  let client = useClient();

  useQuery(
    ["getNotificationsCount", location],
    () => client.get("auth/get-notifications-count/").then((res) => res.data),
    {
      refetchOnMount: "always",
      onSuccess: (data) => {
        setCount(data["detail"]);
      },
    }
  );

  return count;
};
