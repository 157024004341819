import { routes } from "../config/api_routes";
import axios from "axios";
import ReduxState from "./stateType";
import store from "./store";
import { cases } from "./caseDeclarations";

function AuthHandle() {
  let auth_token = localStorage.getItem("token");

  let config = {
    headers: JSON.parse(
      JSON.stringify({
        Authorization: `Token ${auth_token}`,
      })
    ),
  };

  axios
    .get(`${routes.API_URL}auth/is-auth/`, config)
    .then((response) => {
      let data = response.data;

      if (data.detail === "true") {
        store.dispatch({ type: cases.changeIsAuth, data: true });
      } else {
        store.dispatch({ type: cases.changeIsAuth, data: false });
      }
    })
    .catch((error) =>
      store.dispatch({ type: cases.changeIsAuth, data: false })
    );
}

let initState: ReduxState = {
  isAuthenticated: false,
  token: localStorage.getItem("token"),
  isReady: false,
};

export { initState, AuthHandle };
