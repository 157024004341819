import React, { Suspense, useEffect, useState } from "react";
import "focus-visible/dist/focus-visible";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "nprogress/nprogress.css";

// aos for animation on scroll
import AOS from "aos";
import "aos/dist/aos.css";

import "./index.css";
import "./scripts/prism.css";

// top items
import Navbar from "./components/navbar";

// config
import theme from "./config/theme";
import ScrollToTop from "./components/ScrollToTop";

// State management
import store from "./_services/store";
import { Provider } from "react-redux";

// react-query
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import progressInstance from "./components/app/ui/progressInstance";

const queryClient = new QueryClient();

const HomePage = React.lazy(() => import("./pages/home/homePage"));
const ProfilePage = React.lazy(() => import("./pages/profile/profile"));
const ForumPost = React.lazy(
  () => import("./pages/feed/pages/forum/forumPost")
);
const ArticlePost = React.lazy(
  () => import("./pages/feed/pages/article/articlePost")
);
const ImagePost = React.lazy(
  () => import("./pages/feed/pages/image/imagePost")
);

const Page404 = React.lazy(() => import("./components/404"));
const FeedHome = React.lazy(() => import("./pages/feed/feedHomePage"));
const PostQuestion = React.lazy(
  () => import("./pages/feed/pages/post/question/postQuestion")
);
const PostImage = React.lazy(
  () => import("./pages/feed/pages/post/image/postImage")
);
const PostArticle = React.lazy(
  () => import("./pages/feed/pages/post/article/postArticle")
);

const NotificationsHome = React.lazy(() => import("./pages/notifications"));

function LazyLoad() {
  useEffect(() => {
    const NProgress = progressInstance;
    NProgress.start();
    return () => {
      NProgress.done();
    };
  });
  return null;
}

export const UserContext = React.createContext({
  is: false,
  token: "",
  isReady: false,
});

function App() {
  let userStore = store;

  const [isAuthenticated, setIsAuthenticated]: any = useState({
    is: userStore.getState().isAuthenticated,
    token: userStore.getState().token,
    isReady: userStore.getState().isReady,
  });

  userStore.subscribe(() =>
    setIsAuthenticated({
      is: userStore.getState().isAuthenticated,
      token: userStore.getState().token,
      isReady: userStore.getState().isReady,
    })
  );

  AOS.init();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <UserContext.Provider value={isAuthenticated}>
          <React.Fragment>
            <ChakraProvider theme={theme}>
              <BrowserRouter>
                <Navbar />
                <Suspense fallback={<LazyLoad />}>
                  <ScrollToTop>
                    <Box marginBottom="20">
                      <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/feed" component={FeedHome} />
                        <Route
                          exact
                          path="/notifications"
                          component={NotificationsHome}
                        />
                        <Route
                          exact
                          path="/feed/post/question"
                          component={PostQuestion}
                        />
                        <Route
                          exact
                          path="/feed/post/image"
                          component={PostImage}
                        />
                        <Route
                          exact
                          path="/feed/post/article"
                          component={PostArticle}
                        />
                        <Route
                          exact
                          path="/profile/:user_id"
                          component={ProfilePage}
                        />
                        <Route
                          path="/articles/:article_slug"
                          component={ArticlePost}
                        />
                        <Route
                          path="/forum/:forum_slug"
                          component={ForumPost}
                        />
                        <Route
                          path="/images/:image_slug"
                          component={ImagePost}
                        />
                        <Route path="*" component={Page404} />
                      </Switch>
                    </Box>
                  </ScrollToTop>
                </Suspense>
              </BrowserRouter>
            </ChakraProvider>
          </React.Fragment>
        </UserContext.Provider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
