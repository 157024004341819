import axios, { AxiosRequestConfig } from "axios";
import { routes } from "../../config/api_routes";

interface ClientConfig {
  headers?: any;
}

const useClient = (config?: ClientConfig) => {
  let defaultAxiosConfig: AxiosRequestConfig;

  if (config?.headers) {
    defaultAxiosConfig = {
      baseURL: routes.API_URL,
      headers: config.headers,
    };
  } else {
    defaultAxiosConfig = {
      baseURL: routes.API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  let client = axios.create(defaultAxiosConfig);

  client.interceptors.request.use(function (config) {
    let token: string | null = "";

    if (window.localStorage) {
      token = localStorage.getItem("token");
    }

    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    return config;
  });

  return client;
};

export default useClient;
